import { ENDPOINTS, EnvironmentConfig } from './environment.common';

export const environment: EnvironmentConfig = {
  production: true,
  // TODO: Replace with generated env config from infra script
  cognito: {
    Auth: {
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_SeG5kRCUt',
      userPoolWebClientId: '44gf96be6473lti8mub7f6ct24',
      userPoolIdentityProvider: 'transversaldatabase-prod-idp',
      oauth: {
        domain: 'transversaldatabase-prod.auth.eu-west-1.amazoncognito.com',
        scope: [
          'email',
          'profile',
          'openid',
          'aws.cognito.signin.user.admin',
          'phone',
        ],
        redirectSignIn: 'https://prod.transversaldatabase.te-ded.com/dashboard',
        redirectSignOut: 'https://prod.transversaldatabase.te-ded.com/home',
        responseType: 'code',
        options: {
          AdvancedSecurityDataCollectionFlag: true,
        },
      },
    },
  },
  baseUrl: 'https://api.prod.transversaldatabase.te-ded.com',
  websocket: {
    url: 'wss://wss.prod.transversaldatabase.te-ded.com',
    ttl: 60,
  },
  endpoints: ENDPOINTS,
};
